import React from 'react';
import  { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CalendarComponent from './CalendarComponent';



function DoctorsList() {
  const [doctors, setDoctors] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  

  useEffect(() => {
    
    axios.get('/api/get-doctors')
      .then(response => {
        setDoctors(response.data);
      });
  }, []);
  

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor);
    setShowCalendar(true);    
  }

  return (
    <div className="container">
    <div className="row">
      {showCalendar ? (
        <CalendarComponent doctor={selectedDoctor} />
      ) : (
        doctors.map((doctor, index) => (
          <div className="col-md-4" key={index}>
  <div className="card mb-4 text-center">
    <img style={{height:"200px", width:"auto", margin:"auto"}} src={doctor.profile_photo} alt="Doctor" className="card-img-top mx-auto d-block"/>
    <div className="card-body">
      <h5 className="card-title">{doctor.name}</h5>
      <p className="card-text">Specialization: {doctor.specialization}</p>
      <button 
        className="btn btn-primary" 
        onClick={() => handleDoctorSelect(doctor)}
      >
        Book Appointment
      </button>
    </div>
  </div>
</div>

        ))
      )}
    </div>
  </div>
  );
}

export default DoctorsList;






import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function CheckoutComponent() {
  const location = useLocation();
  const [selectedSlot, setSelectedSlot] = useState(location.state?.selectedSlot || '');
  const [selectedDate, setSelectedDate] = useState(location.state?.selectedDate || new Date());
  const [doctor, setDoctor] = useState(location.state?.doctor || {});

  console.log('checkout component');
  console.log('selected slot checkout component', selectedSlot);
  console.log('selected date checkout component', selectedDate);
  console.log('doctor', doctor);

  useEffect(() => {
    const savedState = localStorage.getItem('checkoutState');
    if (savedState) {
      const { selectedSlot, selectedDate, doctor } = JSON.parse(savedState);
      setSelectedSlot(selectedSlot);
      setSelectedDate(new Date(selectedDate)); // Assuming selectedDate is serializable
      setDoctor(doctor);
      localStorage.removeItem('checkoutState'); // Clear the saved state
    }
  }, []);

  const handlePayment = async () => {
    try {
      // Check user authentication status from localStorage
      const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

      if (!isAuthenticated) {
        localStorage.setItem('checkoutState', JSON.stringify({ selectedSlot, selectedDate, doctor }));
        const currentUrl = window.location.href;
        window.location.href = `/login?returnUrl=${encodeURIComponent(currentUrl)}`;
        return;
      }

      // User is authenticated, proceed with Stripe checkout
      const response = await axios.post('/api/create-checkout-session', {
        doctorId: doctor.id,
        doctorName: doctor.name,
        selectedDate,
        selectedSlot,
        consultationFee: doctor.consultation_fee,
        bookingFee: doctor.booking_fee
      });

      // Redirect to Stripe Checkout using the URL from the response
      window.location.href = response.data.checkoutUrl;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!selectedSlot || !doctor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Appointment Details</h2>
              <p className="card-text">Doctor: {doctor.name}</p>
              <p className="card-text">Date: {selectedDate.toLocaleDateString('en-IN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
              <p className="card-text">Time: {selectedSlot}</p>
              <p className="card-text">Consultation Fee: {doctor.consultation_fee}</p>
              <p className="card-text">Booking Fee: {doctor.booking_fee}</p>
              <button className="btn btn-primary w-100" onClick={handlePayment}>Proceed to Payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutComponent;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

function SuccessPage() {
  const { session_id: sessionId } = useParams();

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('session_id',sessionId);
  useEffect(() => {    
    if (sessionId) {
      // Fetch payment details from your backend using the session_id
      axios.get(`https://medicsbooking.com/api/payment-success/${sessionId}`)
        .then(response => {
          console.log('response',response);
          setPaymentDetails(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching payment details:', error);
          setLoading(false);
        });
    }
  }, [sessionId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Payment Successful!</h2>
              {paymentDetails && (
                <>
                  <p className="card-text">Thank you for your payment.</p>
                  <p className="card-text">Appointment ID: {paymentDetails.appointmentId}</p>
                  <p className="card-text">Amount Paid: {paymentDetails.amount}</p>
                  {/* Display other details as needed */}
                </>
              )}
              {!paymentDetails && (
                <p className="card-text">Payment was successful, but we couldn't fetch the details right now.</p>
              )}
              {/* You can add a button or link to redirect the user to another page */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './bootstrap';
import CalendarComponent from './components/CalendarComponent';

import DoctorsList from './components/DoctorsList';
import CheckoutComponent from './components/CheckoutComponent';
import LoginForm from './components/LoginForm';
import { UserProvider } from './context/UserContext';


/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SuccessPage from './components/SuccessPage';

function App() {
  return (
    <UserProvider>
    <BrowserRouter>
      <Routes>         
        <Route path="/doctors" element={<DoctorsList />} />
        <Route path="/calendar" element={<CalendarComponent />} />
        <Route path="/checkout" element={<CheckoutComponent />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/success/:session_id" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
    </UserProvider>
  );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById('root')); // This ID should match the one in your blade template
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

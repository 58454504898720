import Calendar from 'react-calendar';
import  { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutComponent from './CheckoutComponent';

function CalendarComponent({ doctor }) {
  const [date, setDate] = useState(new Date());
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showCheckout,setShowCheckout] = useState(false);
  const [blockedDates, setBlockedDates] = useState({
    fullDays: [],
    weeklyOffs: []
  });
  

  const navigate = useNavigate();

  const today = new Date();
  today.setHours(0, 0, 0, 0); 

  

  const onChange = (date) => {
    console.log('Doctor ID:', doctor.id);
    setDate(date);
    // Fetch available slots for selected date
    const dateObject = new Date(date);  // assuming `date` is the original Date object
    const formattedDate = `${dateObject.getFullYear()}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${String(dateObject.getDate()).padStart(2, '0')}`;
    axios.get(`/api/get-available-slots?doctor_id=${doctor.id}&date=${formattedDate}`)
      .then(response => {
        console.log('response',response.data)
        // Handle the available slots here
        setSlots(response.data);
      })
      .catch(error => {
        console.error('Error fetching available slots:', error);
      });
  };
  const handleSlotClick = (slot) => {
    // Navigate to the payment page, passing the selected slot as parameter
    setSelectedSlot(slot);
    console.log('Selected slot:', slot);
    console.log('selected Doctor',doctor);
    //setShowCheckout(true);
    navigate('/checkout', { state: { selectedSlot: slot, selectedDate: date, doctor: doctor } });

  };
  useEffect(() => {
    // Your axios call here
    axios.get(`/api/get-blocked-dates?doctor_id=${doctor.id}`)
      .then(response => {
        const blockedFullDays = response.data.blockedFullDays || [];
        const weeklyOffs = response.data.weeklyOffs || [];
        console.log("Blocked Full Days: ", blockedFullDays);
        console.log("Weekly Offs: ", weeklyOffs);
  
        // Convert blockedFullDays to Date objects
        const blockedFullDaysAsDates = blockedFullDays.map(day => new Date(day));
  
        // Update the blockedDates state
        setBlockedDates(prevBlockedDates => ({
          fullDays: blockedFullDaysAsDates,
          weeklyOffs: weeklyOffs
        }));
      })
      .catch(error => {
        console.log("Error fetching blocked dates: ", error);
      });
  }, []);
  
  const isDateBlocked = (date) => {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = dayNames[date.getDay()];  // getDay() returns the index of the day [0, 6]
  
    // Check if the date itself is blocked
    const isFullDayBlocked = blockedDates.fullDays?.some(blockedDate => blockedDate.toDateString() === date.toDateString());
  
    // Check if the weekday is blocked
    const isWeeklyOff = blockedDates.weeklyOffs?.includes(dayName);
  
    return isFullDayBlocked || isWeeklyOff;
  };

  return (
    <div>
     
      <h2>Select a date for appointment with Dr. {doctor.name}</h2>
      <Calendar
        onChange={onChange}
        value={date}
        minDate={today}
        tileDisabled={({ date, view }) => 
    // Disable the tile if the date is in the blockedDates array
    view === 'month' && isDateBlocked(date)}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {slots.map((slot, index) => (
          <button
            key={index}
            onClick={() => handleSlotClick(slot)}
            style={{
              margin: '10px',
              padding: '10px',
              border: '1px solid black',
              backgroundColor: slot === selectedSlot ? 'green' : 'white',
              color: slot === selectedSlot ? 'white' : 'black'
            }}
          >
            {slot}
          </button>
        ))}
      </div>
    
    </div>
  );
}

export default CalendarComponent;

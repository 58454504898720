import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const redirectTo = new URLSearchParams(location.search).get('returnUrl') || '/dashboard';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/login', { email, password });
            if (response.data.success) {
                // Set isAuthenticated to true in localStorage upon successful login
                localStorage.setItem('isAuthenticated', 'true');
                
                // Redirect to the originally requested page or dashboard
                window.location.href = redirectTo;
            }
        } catch (err) {
            setError('Invalid credentials or server error');
        }
    };

    return (
        <div className="login-form">
            <div class="row justify-content-center">
            <div class="col-md-8">
            <div class="card">
            <form onSubmit={handleSubmit}>
            <div class="card-header">
                <h2>Login</h2></div>
                {error && <div className="error">{error}</div>}
                <div class="card-body">
                <div class="row mb-3">

                
                    <label htmlFor="email" className="col-md-4 col-form-label text-md-end">Email</label>
                    <div class="col-md-6">
                    <input 
                        type="email" 
                        placeholder='user name or email'
                        className="form-control"
                        id="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    </div>
                </div>
                <div class="row mb-3">

                
                    <label htmlFor="password" className="col-md-4 col-form-label text-md-end">Password</label>
                    <div class="col-md-6">
                    <input 
                        type="password" 
                        className="form-control"
                        id="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                </div></div>
                <div class="row mb-3">
                            <div class="col-md-8 offset-md-4">

                <button className="btn btn-primary" type="submit">Login</button>
                </div></div></div>
            </form>
            </div>
            </div>
            </div>
        </div>
    );
}

export default LoginForm;
